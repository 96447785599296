import React from "react";

import { SubHeading, MenuItem } from "../../components";
import { data, images } from "../../constants";
import "./SpecialMenu.css";

// Лучше перезагрузить локал хост для тяги данных

const SpecialMenu = () => (
  <div className="app__specialMenu flex__center section__padding" id="menu">
    <div className="app__specialMenu-title">
      <SubHeading title="Menu that fits your palatte" />
      <h1 className="headtext__cormorant">Menu of Services</h1>
    </div>

    <div className="app__specialMenu-menu">
      <div className="app__specialMenu-menu_wine  flex__center">
        <p className="app__specialMenu-menu_heading">POS packet</p>
        <div className="app__specialMenu_menu_items">
          {data.posPacket.map((packet, index) => (
            <MenuItem
              key={packet.title + index}
              title={packet.title}
              price={packet.price}
              tags={packet.description}
            />
          ))}
        </div>
      </div>

      <div className="app__specialMenu-menu_img">
        <img src={images.menu} alt="menu__img" />
      </div>

      <div className="app__specialMenu-menu_cocktails  flex__center">
        <p className="app__specialMenu-menu_heading">Services</p>
        <div className="app__specialMenu_menu_items">
          {data.services.map((service, index) => (
            <MenuItem
              key={service.title + index}
              title={service.title}
              price={service.price}
              tags={service.description}
            />
          ))}
        </div>
      </div>
    </div>

    <div style={{ marginTop: 15 }}>
      <a href="#ar" type="button" className="custom__button">
        View More
      </a>
    </div>
  </div>
);

export default SpecialMenu;
