import bg from "../assets/bg.jpeg";
import ar from "../assets/ar.png";
import G from "../assets/G.png";
import gallery01 from "../assets/gallery01.png";
import gallery02 from "../assets/gallery02.png";
import gallery03 from "../assets/gallery03.png";
import gallery04 from "../assets/gallery04.png";
import gallery05 from "../assets/gallery05.png";
import gallery06 from "../assets/gallery06.jpeg";
import gallery07 from "../assets/gallery07.jpeg";
import knife from "../assets/knife.png";
import logo from "../assets/logo.png";
import menu from "../assets/menu.png";
import overlaybg from "../assets/overlaybg.png";
import spoon from "../assets/spoon.svg";
import welcome from "../assets/welcome.png";
import findus from "../assets/findus.png";
import laurels from "../assets/laurels.png";
import award01 from "../assets/award01.png";
import award02 from "../assets/award02.png";
import award03 from "../assets/award03.png";
import award04 from "../assets/award04.png";
import sign from "../assets/sign.png";
import quote from "../assets/quote.png";
import gericht from "../assets/gericht.png";
import popoxvac1 from "../assets/popoxvac1.jpg";
import popoxvac2 from "../assets/popoxvac2.jpg";
import popoxvac3 from "../assets/popoxvac3.jpg";
import popoxvac4 from "../assets/popoxvac4.jpg";
import popoxvac5 from "../assets/popoxvac5.jpg";
import popoxvac6 from "../assets/popoxvac6.jpg";
import popoxvac7 from "../assets/popoxvac7.jpg";
import popoxvac8 from "../assets/popoxvac8.jpg";
import start from "../assets/start.png";
export default {
  bg,
  ar,
  G,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  gallery05,
  gallery06,
  gallery07,
  knife,
  logo,
  menu,
  overlaybg,
  spoon,
  welcome,
  findus,
  laurels,
  award01,
  award02,
  award03,
  award04,
  sign,
  quote,
  gericht,
  popoxvac1,
  popoxvac2,
  popoxvac3,
  popoxvac4,
  popoxvac5,
  popoxvac6,
  popoxvac7,
  popoxvac8,
  start,
};
