import React from "react";
import { FiFacebook, FiTwitter, FiInstagram } from "react-icons/fi";

import { FooterOverlay, Newsletter } from "../../components";
import { images } from "../../constants";
import '@fortawesome/fontawesome-free/css/all.min.css';
import "./Footer.css";


const Footer = () => (
  <div className="app__footer section__padding">
    {/* <FooterOverlay /> */}
    {/* <Newsletter /> */}

    <div className="app__footer-links">
      {/* <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Contact Us</h1>
        <p className="p__opensans">9 W 53rd St, New York, NY 10019, USA</p>
        <p className="p__opensans">+1 212-344-1230</p>
        <p className="p__opensans">+1 212-555-1230</p>
      </div> */}

      <div className="app__footer-links_logo">
        <img src={images.gericht} alt="footer_logo" />
        <p className="p__opensans">
          Feel free to Contact Us for more Details
        </p>
        <p className="p__opensans">24/7 customer support</p>
        <img
          src={images.spoon}
          className="spoon__img"
          style={{ marginTop: 15 }}
        />
        <div className="app__footer-links_icons">
          <ul class="social-icons">
            <li><a href="https://t.me/foodie_support_bot"><i class="fab fa-telegram"></i></a></li>
            {/* <li><a href="#"><i class="fab fa-instagram"></i></a></li>
      <li><a href="#"><i class="fab fa-linkedin"></i></a></li>*/}
          </ul>
        </div>
      </div>

      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Working Hours</h1>
        <p className="p__opensans">Monday-Friday:</p>
        <p className="p__opensans">09:00-19:00</p>
        <p className="p__opensans">Saturday:</p>
        <p className="p__opensans">16:00-10:00</p>
      </div>
    </div>



    <div className="footer__copyright">
      <p className="p__opensans">2024 Foodie GSR.</p>
    </div>
  </div>
);

export default Footer;
