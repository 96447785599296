import React from "react";

import { images } from "../../constants";
import "./AboutUs.css";

const AboutUs = () => (
  <div
    className="app__aboutus app__bg flex__center section__padding"
    id="about"
  >
    <div className="app__aboutus-overlay flex__center">
      <img src={images.G} alt="G_overlay" />
    </div>

    <div className="app__aboutus-content flex__center">
      <div className="app__aboutus-content_about">
        <h1 className="headtext__cormorant">About Us</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">
          We are Creating Seamless Experiences for Restaurants
          Transforming Dining Experiences with Cutting-edge Technology
          Our team of professionals has successfully implemented cutting-edge solutions for restaurants.
        </p>
        <a href="#menu" type="button" className="custom__button">
          Know More
        </a>
      </div>

      <div className="app__aboutus-content_knife flex__center">
        <img src={images.knife} alt="about_knife" />
      </div>

      <div className="app__aboutus-content_history">
        <h1 className="headtext__cormorant">Our Focus</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">
          Shaping the Future of Restaurants with Innovative Solutions.
          Our brief yet impactful journey is a testament to our dedication to shaping the future of the restaurant industry.
        </p>
        <a href="#menu" type="button" className="custom__button">
          Know More
        </a>
      </div>
    </div>
  </div>
);

export default AboutUs;
